import { ModalResult, WModalContent } from "../../../components/modal/WModal.tsx";
import { raiseBackgroundErrorToast } from "../../notifications/toast.tsx";
import type { EBillingAccount } from "../billingLogic.tsx";
import { AddMethodResult } from "./AddMethodParams.ts";
import { ChooseMethod, type ChooseMethodProps } from "./ChooseMethod.tsx";
import { useAddMethod, type UseAddMethodProps } from "./useAddMethod.tsx";

export interface AddPaymentMethodModalProps extends UseAddMethodProps {
    account: EBillingAccount;
    onlyAutomated?: ChooseMethodProps["defaultOnlyAutomated"];
}

export function AddPaymentMethodModal({ account, onlyAutomated, ...useProps }: AddPaymentMethodModalProps) {
    const adder = useAddMethod({ ...useProps, account });

    return (
        <WModalContent
            title="Add Payment Method"
            label="Add"
            footerNotice={!adder.hasSelectedMethod ? "Please select an item in the list" : undefined}
            isActionDisabled={!adder.hasSelectedMethod}
            modalAction={async (ev) => {
                if (!(await adder.validate(ev))) {
                    return ModalResult.KEEP_OPEN;
                }

                try {
                    const result = await adder.addPaymentMethod({ account });
                    return result === AddMethodResult.SUCCESS ? ModalResult.CLOSE : ModalResult.KEEP_OPEN;
                } catch (e) {
                    raiseBackgroundErrorToast(e, "Error adding payment method");
                    return ModalResult.KEEP_OPEN;
                }
            }}
        >
            <ChooseMethod hasAutomatedSwitch defaultOnlyAutomated={onlyAutomated} scrollToSelected {...adder.props} />
        </WModalContent>
    );
}

export default AddPaymentMethodModal;
