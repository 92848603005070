import { abortSignalToPromise } from "@warrenio/utils/promise/abortSignalToPromise";
import { SECONDS } from "@warrenio/utils/timeUnits";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import isChromatic from "chromatic";
import { getDefaultStore } from "jotai/vanilla";
import invariant from "tiny-invariant";
import { getBodyClasses } from "../../../styles/bodyClasses.ts";
import { unsetPropertyCheckProxy } from "../../../utils/debug/unsetPropertyCheckProxy.ts";
import { isTestEnvironment } from "../../../utils/environment.ts";
import { makePromise } from "../../../utils/promise/makePromise.ts";
import { showError } from "../../error/errorStream.ts";
import { currentColorSchemeAtom } from "../../main/ColorSchemeSwitcher.store.ts";
import { getFullUrl } from "../../main/urls.ts";
import { colorSchemeAttribute } from "../../theme/shared.ts";
import { themeCssHtmlAtom } from "../../theme/themeCssHtml.ts";
import popupCss from "./popup.css?inline";
import { isMockVerifyPage } from "./popup.mock.ts";

// IDs of elements inside the popup
const rootId = "popup-root";
const textId = "popup-text";

function makeHtml() {
    const store = getDefaultStore();

    const styleUrls: string[] = [];
    const styleTags = styleUrls.map((url) => `<link rel="stylesheet" href="${url}">`).join("\n");

    const themeCss = store.get(themeCssHtmlAtom);

    const devClass = isChromatic() ? " chromatic" : "";

    return `
<!DOCTYPE html>
<html>
<head>
    <base href="${getFullUrl("/")}">
    <title>Popup</title>
    ${styleTags}
    ${themeCss}
    <style>${popupCss}</style>
</head>
<body class="${getBodyClasses()}${devClass}" ${colorSchemeAttribute}="${store.get(currentColorSchemeAtom)}">
    <div id="${rootId}">
        <div class="Spinner"><div class="Content"></div></div>
        <div id="${textId}">Loading...</div>
    </div>
</body>
</html>
`;
}

/** Mockable version of {@link Window} */
export interface SimpleWindow {
    document: Document;
    closed: boolean;
    close(): void;
    toString(): string;
}

type PopupState = "initial" | "redirected" | "returned" | "closed";

export class PopupDidNotReturnError extends Error {
    name = "PopupDidNotReturnError";
    constructor(public redirectUrl: string | undefined) {
        super("Popup did not return from redirect");
    }
}

class PopupClosedError extends Error {
    name = "PopupClosedError";
    constructor() {
        super("Popup was closed before it could load");
    }
}

export class PaymentPopup {
    private disposed = false;
    private forceClosed = false;

    private autoCloseOnReturn = true;

    private redirectUrl: string | undefined;

    /** Promise that is fulfilled when the popup returns from redirect */
    private returnPromise = makePromise<void>();

    /**
     * A timer that periodically checks if the popup has returned.
     *
     *  Necessary because the user can close the popup at any point and if we do not check before than, we will never know whether it returned or not.
     */
    private stateCheckTimer: ReturnType<typeof setInterval> | null | undefined;

    constructor(private readonly window: SimpleWindow) {
        console.debug("popup: Constructing", String(window));

        // NB: Can not use `Blob` for content since it will not have the same origin
        window.document.documentElement.innerHTML = makeHtml();
    }

    /** @param isTrusted - Whether the currently handled event is trusted (eg. generated by a user action) */
    static open(isTrusted: boolean): PaymentPopup | undefined {
        // NB: If the event is generated by eg. react-testing-library, it will not be trusted and a real popup can not be opened
        // (so force attaching to the mock popup)
        if (import.meta.env.DEV && (!isTrusted || isChromatic())) {
            return attachMockPopup();
        }

        const w = window.open("", "_blank");
        if (!w) {
            if (isTestEnvironment) {
                console.warn("popup: Fallback to mock popup in tests");
                return attachMockPopup();
            }

            console.warn("popup: Failed to open window");
            return undefined;
        }

        try {
            // XXX: Try to work around issue with Mobile Safari opening the tab in the background
            w.focus();
        } catch (e) {
            console.warn("popup: Failed to focus the window", e);
        }

        return new PaymentPopup(w);
    }

    //#region State checking
    private startStateCheck() {
        const STATE_CHECK_INTERVAL = 50;

        invariant(this.stateCheckTimer === undefined, "State check was already started");
        this.stateCheckTimer = setInterval(() => this.runStateCheck(), STATE_CHECK_INTERVAL);
    }

    private _lastState: PopupState = "initial";

    private runStateCheck() {
        const state = this.getPopupState();
        if (state === this._lastState) {
            return;
        }

        console.info("popup: State changed: %s -> %s", this._lastState, state);
        this._lastState = state;

        switch (state) {
            case "initial":
            case "redirected":
                // Do nothing
                break;

            case "returned":
                this.returnPromise.resolve();
                if (this.autoCloseOnReturn) {
                    console.info("popup: Automatically closing after it has returned");
                    this.close();
                }
                break;

            case "closed":
                // State can not change after closed
                this.stopStateCheck(false);
                break;

            default:
                exhaustiveSwitchCheck(state);
        }
    }

    /**
     * Stop the state check timer if we are in a "final" state.
     *
     * Otherwise, if {@link finalWait} is `true`, wait for a while and retry. This might happen when this object is `dispose()`d before the popup has been closed (either automatically or manually via `close()`).
     * This can generally only happen in an error case.
     *
     * If `false`, throw an error if we are in a non-final state.
     */
    private stopStateCheck(finalWait: boolean) {
        const GRACEFUL_DISPOSE_TIMEOUT = 60 * SECONDS;

        if (this.stateCheckTimer == null) {
            return;
        }

        // `returnPromise` must transition to its final state here
        if (this.returnPromise.state === "pending") {
            if (this.forceClosed || this.redirectUrl === undefined) {
                this.returnPromise.resolve();
            } else if (finalWait) {
                console.info("popup: Waiting for state check after dispose");
                setTimeout(() => this.stopStateCheck(false), GRACEFUL_DISPOSE_TIMEOUT);
                // NB: Skip clearing the timer here, because we want to retry
                return;
            } else {
                this.returnPromise.reject(new PopupDidNotReturnError(this.redirectUrl));
            }
        }

        // console.debug("popup: Stopping state check");
        clearInterval(this.stateCheckTimer);
        this.stateCheckTimer = null;
    }

    private getPopupState(): PopupState {
        if (this.closed) {
            return "closed";
        }
        if (!this.hasRedirected) {
            return "initial";
        }

        try {
            const location = this.window.document.location;
            // NB: `location` can be `null` if it is a *new* about:blank page
            if (location == null) {
                return "redirected";
            } else if (location.href === "about:blank") {
                return "initial";
            } else if (isMockVerifyPage(location.href)) {
                return "redirected";
            } else {
                // If `location` is accessible and, it means we are on the original origin, therefore assume the redirect is done
                return "returned";
            }
        } catch (e) {
            if (!isSecurityError(e)) {
                console.error("popup: Error in hasReturned", e);
            }
            return "redirected";
        }
    }
    //#endregion

    get hasRedirected() {
        return this.redirectUrl !== undefined;
    }

    handleRedirect(url: string) {
        console.debug("popup: Redirecting to %s", url);

        invariant(!this.hasRedirected, "Popup must not have redirected before");

        const location = this.window.document.location;
        if (!location) {
            throw new PopupClosedError();
        }

        // NB: Replace the content so that the user can not go back to the previous (empty about:blank) page
        location.replace(url);

        this.redirectUrl = url;

        this.startStateCheck();
    }

    /** Wait for the popup to return from the redirect */
    async waitReturnFromRedirect(signal: AbortSignal) {
        invariant(this.hasRedirected, "Popup must have redirected before waiting for redirect");

        await Promise.race([this.returnPromise, abortSignalToPromise(signal)]);
    }

    get hasReturned() {
        return this.returnPromise.state === "resolved";
    }

    dispose() {
        if (this.disposed) {
            return;
        }
        this.disposed = true;

        if (!this.hasRedirected) {
            console.debug("popup: dispose() before redirect");
            this.forceClose();
        }

        // Gracefully stop the state check
        this.stopStateCheck(true);
    }

    close() {
        console.debug("popup: close()");

        this.forceClose();

        if (!this.hasReturned) {
            console.warn("popup: was closed before it had returned from redirect");
        }
    }

    private forceClose() {
        this.forceClosed = true;
        try {
            this.window.close();
        } catch (e) {
            showError("popup: Error in close", e);
        }
    }

    get closed() {
        return this.window.closed;
    }

    setText(text: string) {
        try {
            const root = this.window.document.getElementById(textId)!;
            root.innerText = text;
        } catch (e) {
            console.warn("popup: Error in setText", e);
        }
    }

    toString() {
        return `Popup(${getLocationSafe(this.window)}, state=${this.getPopupState()}, forceClosed=${this.forceClosed})`;
    }
}

function getLocationSafe(window: SimpleWindow): string {
    try {
        if (window.closed) {
            return "<closed>";
        }
        const location = window.document.location;
        if (location == null) {
            return `<${location}>`;
        }
        return location.href;
    } catch (e) {
        if (isSecurityError(e)) {
            return "<cross-origin>";
        }
        console.warn("popup: Error in getLocation", e);
        return "<unknown>";
    }
}

function isSecurityError(e: unknown) {
    return e instanceof DOMException && e.name === "SecurityError";
}

//#region Mock popup

export const mockPopupId = "mock-popup-frame";
export const mockPopupClosedId = "popup-closed";

function attachMockPopup(): PaymentPopup | undefined {
    const iframe = document.getElementById(mockPopupId) as HTMLIFrameElement | null;
    if (!iframe) {
        console.warn("Mock popup: iframe not found");
        return undefined;
    }

    const logLocation = () => {
        console.debug("Mock popup location: %s", getLocationSafe(iframe.contentWindow!));
    };

    iframe.onload = logLocation;

    let closed = false;
    const window: SimpleWindow = {
        get document() {
            invariant(!closed, "Mock popup accessed while closed");

            // NB: The iframe could be destroyed eg. when a test has navigated away from the page - so return a mock `Document` object
            return iframe.contentDocument ?? unsetPropertyCheckProxy<Document>({ location: undefined });
        },
        get closed() {
            return closed;
        },
        close() {
            console.debug("Mock popup: close()");
            if (closed) {
                return;
            }
            closed = true;

            const showClosePage = () => {
                iframe.srcdoc = /*html*/ `<html><body style="background-color: lavender">❎ [<span data-testid="${mockPopupClosedId}">Popup closed</span>]</body></html>`;
            };

            const onLoad = () => {
                showClosePage();
                iframe.removeEventListener("load", onLoad);
            };

            showClosePage();

            // NB: We must load the close page twice, because loading it can race with a redirect inside the iframe (ie. the redirected URL can overwrite the close page)
            iframe.addEventListener("load", onLoad);
        },
        toString() {
            return "[object MockPopup]";
        },
    };

    return new PaymentPopup(window);
}

//#endregion
