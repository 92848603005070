import { useQueryClient } from "@tanstack/react-query";
import { notNull } from "@warrenio/utils/notNull";
import { useAtomValue, useStore } from "jotai/react";
import { atom } from "jotai/vanilla";
import { useEffect, useMemo, useRef, useState } from "react";
import type { PressEvent } from "react-aria";
import invariant from "tiny-invariant";
import { unhandledNestedError } from "../../../utils/error.ts";
import { useOnce } from "../../../utils/react/useOnce.ts";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { showWarn } from "../../error/errorStream.ts";
import { raiseBackgroundErrorToast } from "../../notifications/toast.tsx";
import type { PaymentMethod } from "../PaymentMethod.tsx";
import type { EBillingAccount } from "../billingLogic.tsx";
import { processorsAtom } from "../paymentProcessorsLogic.tsx";
import { PaymentPopup } from "../popup/popup.ts";
import { getPaymentReturnUrl } from "../topup/urls.ts";
import {
    AddMethodResult,
    type AddMethodActions,
    type AddMethodParams,
    type MethodAdderParams,
} from "./AddMethodParams.ts";
import type { ChooseMethodContentProps, ChooseMethodProps } from "./ChooseMethod.tsx";
import { invalidateBillingCards } from "./addMethodUtils.ts";

export interface UseAddMethodProps {
    defaultValue?: ChooseMethodProps["value"];
    account?: EBillingAccount;
}

export function useAddMethod(props?: UseAddMethodProps) {
    const methods = useAtomValue(processorsAtom);
    const addableMethods = useMemo(() => [...methods.values()].filter((m) => m.canAdd), [methods]);
    return useAddMethodWithProcessors(addableMethods, props);
}

export function useAddMethodWithProcessors(methods: PaymentMethod[], props?: UseAddMethodProps) {
    const queryClient = useQueryClient();

    const [value, setValue] = useState<ChooseMethodProps["value"]>(() => {
        const defaultValue = props?.defaultValue;
        if (defaultValue == null) {
            return null;
        }
        if (!methods.some((m) => m.id === defaultValue)) {
            showWarn(
                "Invalid default value for `useAddMethod`: %o (valid: %o)",
                defaultValue,
                methods.map((m) => m.id),
            );
            return null;
        }
        return defaultValue;
    });
    const statusAtom = useOnce(() => atom<string | null>(null));
    const store = useStore();

    // Forward actions to the form via a ref / `useImperativeHandle`
    const actionsRef = useRef<AddMethodActions>(null);
    function getActions() {
        return notNull(actionsRef.current, "form ref");
    }

    function showError(err: unknown) {
        raiseBackgroundErrorToast(err, "Adding payment method failed", {
            type: "error",
            icon: getResourceIconClass("billing_account"),
        });
    }

    /** `null` means no popup needed */
    const popupRef = useRef<PaymentPopup | null>(undefined);

    function dispose() {
        popupRef.current?.dispose();
    }

    useEffect(() => {
        return () => {
            dispose();
        };
    }, []);

    return {
        async validate(ev: PressEvent | null) {
            if (value == null) {
                console.warn("No payment method selected");
                return false;
            }

            if (!(await getActions().validate())) {
                return false;
            }

            // Open the popup in the early validation function because `addPaymentMethod` might be called asynchronously later
            if (getActions().needsPopUp?.()) {
                // NB: The popup must be opened synchronously (before anything `async`), otherwise the browser (eg. Safari) will block it
                const popup = PaymentPopup.open(!ev || ev.pointerType !== "virtual");
                if (!popup) {
                    showError("Please allow popup windows and retry to add this payment method");
                    return false;
                }

                // Close the previous popup, if it exists (in case of a retry)
                dispose();

                popupRef.current = popup;
            } else {
                popupRef.current = null;
            }
            return true;
        },

        async addPaymentMethod(params: MethodAdderParams): Promise<AddMethodResult> {
            invariant(popupRef.current !== undefined, "Popup must be prepared before calling (by `validate()`)");

            const isValid = await getActions().validate();
            invariant(isValid, "Must be validate()'d before calling");

            const addParams: AddMethodParams = {
                // TODO: Use a specific return URL for adding payment methods
                returnUrl: getPaymentReturnUrl(),
                progress(status) {
                    status += "…";
                    console.debug("Add payment method progress: %s", status);
                    const { popup } = addParams;
                    if (popup) {
                        // TODO: Check if window closed
                        popup.setText(status);
                    }
                    store.set(statusAtom, status);
                },
                popup: popupRef.current,
                ...params,
            };

            try {
                addParams.progress("Initiating adding payment method");
                return (await getActions().addPaymentMethod(addParams)) ?? AddMethodResult.SUCCESS;
            } finally {
                dispose();

                try {
                    await invalidateBillingCards(queryClient, params.account.id);
                } catch (e) {
                    unhandledNestedError(e);
                }
            }
        },

        /** NB: MUST be called after `validate()` returns `true` regardless of whether `addPaymentMethod()` is called or not */
        dispose,

        /** Props to send to <ChooseMethod> */
        props: {
            formProps: { actionsRef, account: props?.account },
            value,
            onChange: setValue,
            methods,
            statusAtom,
            // TODO: Set `isDisabled` when action is in progress
        } satisfies ChooseMethodContentProps,

        hasSelectedMethod: value != null,
    };
}
